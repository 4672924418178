import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Map } from './routes/Map';
import { Welcome } from './routes/Welcome';
import axios from 'axios';
import { baseURLBookingSystem } from './util/instances';
import { AddParking } from './routes/AddParking';
import { AppProvider } from './util/UserContext';
import { ModifyParking } from './routes/ModifyParking';
import { ErrorPage } from './routes/ErrorPage';
import { Users } from './routes/Users';
import { Reports } from './routes/Reports';


const loadParking = async () => {
  const parking = await axios.get(baseURLBookingSystem + 'parking/all');
  if(parking.status !== 200)
    return {parking: []}
  if(parking.data === undefined)
    return {parking: []}
  return { parking:parking.data.spots };
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: loadParking,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Welcome />
      },
      {
        path: "map",
        element: <Map />
      },
      {
        path: "users",
        element: <Users />
      },
      {
        path: "reports",
        element: <Reports />
      }
    ]
  },
  {
    path: "/map/add",
    element: <AddParking />,
    errorElement: <ErrorPage />,
    loader: loadParking,
  },
  {
    path: "/map/modify",
    element: <ModifyParking />,
    errorElement: <ErrorPage />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
    <RouterProvider router={router} />
  </AppProvider>
);


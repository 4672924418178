import { useContext, useState } from 'react'
import Logo from './util/Logo'
import { FaMapMarkedAlt, FaUsers } from 'react-icons/fa'
import { TbReportSearch } from 'react-icons/tb'
import { BiLogOut } from 'react-icons/bi'
import { HiMenu } from 'react-icons/hi'
import { RiMenuFoldFill } from 'react-icons/ri'
import { Link, Outlet, useLoaderData } from 'react-router-dom'
import { UserContext } from './util/UserContext.js'
import { authInstance } from './util/instances'
const App = () => {
  const [showMenu, setMenu] = useState(true);
  const { parking } = useLoaderData();
  const context = useContext(UserContext);
  return (
      <div style={{display: "flex", width: "100%", height: "100%",boxSizing:"border-box"}}>
        <div className='shadow' style={{display:"flex", flexDirection: "column", transition: ".5s ease", width:  showMenu ? 'min(300px,80%)' : 0, position:"absolute", backgroundColor: "white", zIndex: "2", height: "100%", overflow: 'hidden'}}>
          <Link to={''}><Logo style={{marginBottom: "1em", marginTop: "2em"}}/></Link>
          <Link to={'map'} className='menuHover'>
            <FaMapMarkedAlt style={{color: "var(--purple)"}}/>
            <span style={{marginLeft:"1em", display: showMenu ? "inherit" : "none", textOverflow:"ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Map Editor</span>
          </Link>
          <Link to={'users'} className='menuHover'>
            <FaUsers style={{color: "var(--purple)"}}/>
            <span style={{marginLeft:"1em", display: showMenu ? "inherit" : "none", textOverflow:"ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Users</span>
          </Link>
          <Link to={'reports'} className='menuHover'>
            <TbReportSearch style={{color: "var(--purple)"}}/>
            <span style={{marginLeft:"1em", display: showMenu ? "inherit" : "none", textOverflow:"ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Reports</span>
          </Link>
          <div onClick={() => {context.setLoggedIn(false);context.setUsername("");authInstance().get("/logout");}} className='menuHover'>
            <BiLogOut style={{color: "var(--purple)"}}/>
            <span style={{marginLeft:"1em", display: showMenu ? "inherit" : "none", textOverflow:"ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>Logout</span>
          </div>
        </div>
        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <div className='shadowDown' style={{fontSize: "1.7em", paddingTop: ".2em", display: "flex", justifyContent: "space-between", zIndex: "1", backgroundColor: "white", paddingLeft: showMenu ? 'min(310px,82vw)' : '.5em', transition: ".4s ease"}}>
            {!showMenu ? <HiMenu className='hover' style={{cursor: "pointer", borderRadius: "20em", transition: ".4s ease"}} onClick={() => setMenu(!showMenu)}/> :
            <RiMenuFoldFill className='hover' style={{cursor: "pointer", borderRadius: "20em", transition: ".4s ease"}} onClick={() => setMenu(!showMenu)}/>}
          </div>
          <div style={{flexGrow: 1, zIndex: "0", overflow: "auto"}}>
            <Outlet context={[showMenu,parking]}/>
          </div>
          {showMenu && <div style={{position:"absolute", width: "100%", height: "100%",backgroundColor: "rgba(215,215,215,0.54)", zIndex: "0", transition: ".5s ease"}} onClick={() => setMenu(false)}/>}
        </div>
      </div>
  )
}

export default App
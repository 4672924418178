import { createContext, useLayoutEffect, useState } from 'react'
import { Login } from '../routes/Login';
import Cookies from 'js-cookie';

export const UserContext = createContext();


export const AppProvider = ({ children }) => {
    
  const [loggedIn, setLoggedIn] = useState("");
  const [username, setUsername] = useState("");
  const userConfig = {
    loggedIn: loggedIn,
    setLoggedIn,
    username: username,
    setUsername
  };

  useLayoutEffect(() => {
    if(Cookies.get('loggedIn') === 'true') {
      setLoggedIn(true);
      setUsername(Cookies.get('user'));
    } else {
      setLoggedIn(true);
      setUsername("");
    }
    return () => {};
  },[])
  

  if(!loggedIn) 
    return <UserContext.Provider value={userConfig}><Login /></UserContext.Provider>
  
    return <UserContext.Provider value={userConfig}>{children}</UserContext.Provider>;
  };
import React from 'react'
import Logo from '../util/Logo'

export const Welcome = () => {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "2em", padding: "5em"}}>
      <div style={{fontSize: "2em", backgroundColor: "var(--lighterMoreGray)", width: "max(70%,300px)", textAlign: "center", padding: "2em 0 2em 0", borderRadius: "1em", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <span>Welcome to Dashboard</span>
        <Logo style={{width: "max(50%,300px)"}}/>
      </div>
    </div>
  )
}

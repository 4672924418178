import { useContext, useEffect, useRef, useState } from 'react'
import { Circle, MapContainer, Polygon, TileLayer, useMapEvent } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { IoArrowBackSharp } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { parkingInstance } from '../util/instances';
import { UserContext } from '../util/UserContext';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { getCenter, getDistance } from 'geolib';

const provider = new OpenStreetMapProvider();

export const ModifyParking = () => {
  const [map, setMap] = useState(null);
  const location = useLocation();
  const { parking } = location.state;
  const [name, setName] = useState(parking.name.substring(0,parking.name.lastIndexOf(' ')));
  const nameRef = useRef(null);
  const [address, setAddress] = useState(parking.address);
  const addressRef = useRef(null);
  const [from, setFrom] = useState(parking.openTime.split(" - ")[0]);
  const fromRef = useRef(null);
  const [to, setTo] = useState(parking.openTime.split(" - ")[1]);
  const toRef = useRef(null);
  const [spots, setSpots] = useState(parking.spots);
  const spotsRef = useRef(null);
  const [price, setPrice] = useState(parking.price);
  const priceRef = useRef(null);
  const [locker, setLocker] = useState(parking.status);
  const [coords, setCoords] = useState(parking.coordinates.map(e => [e.latitude, e.longitude]));
  const [info, setInfo] = useState([]);
  const [facilities, setFacilities] = useState(parking.facilities);
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const AddMarkers = () => {
    useMapEvent('click', (e) => {
        const { lat, lng } = e.latlng;
        setCoords([...coords,[lat, lng]]);
    })
    return null
  };

  useEffect(() => {
    if(info.length !== 0) {
        window.scrollTo(0,0);
    }
  }, [info])
  

  const equals = (a, b) =>
  a.length === b.length &&
  a.every((v, i) => {if(Array.isArray(v)){return v.every((x,o) => x === b[i][o])} return v === b[i]});

  const handleDelete = () => {
    parkingInstance(context).delete("/delete/" + parking.ID)
    .then(req => {
        navigate('/map');
    })
    .catch(error => {
        setInfo(["Error","There was an error removing the parking space!"]);
    });
  }

  const handleModify = () => {
    if(name === parking.name.substring(0,parking.name.lastIndexOf(' ')) && address === parking.address && from === parking.openTime.split(" - ")[0] && to === parking.openTime.split(" - ")[1] && spots === parking.spots && price === parking.price && locker === parking.status && equals(coords, parking.coordinates.map(e => [e.latitude, e.longitude])) && equals(facilities, parking.facilities)){
        setInfo(["Advice", "Try to change something before modifying in the system!"])
        return;
    }
    if(name === ""){
        nameRef.current.focus();
        return;
    }
    if(address === ""){
        addressRef.current.focus();
        return;
    }
    if(from === ""){
        fromRef.current.focus();
        return;
    }
    if(to === ""){
        toRef.current.focus();
        return;
    }
    if(spots === ""){
        spotsRef.current.focus();
        return;
    }
    if(price === ""){
        priceRef.current.focus();
        return;
    }
    if(coords.length === 0){
        setInfo(["Complete all inputs","Please select the area of the parking space on the map!"]);
        return;
    }

    if(facilities.length === 0 || facilities.includes("") || facilities.includes(" ")){
        setInfo(["Complete all inputs","Please write all facilities!"]);
        return;
    }
    parkingInstance(context).post("/modify",{ID: parking.ID,name:name, address: address, openTime: from + " - " + to, spots: locker === 1 ? 0 : spots, price: price, facilities: facilities, coords: coords, locker: locker})
    .then(req => {
        navigate("/map");
    })
    .catch(error => {
        setInfo(["Error","There was an error modifying the parking space!"]);
    })
  };

  const getCoordsArray = () => {
    const {latitude, longitude} = getCenter(coords.map(e => {return {latitude: e[0], longitude: e[1]}}));
    return [latitude, longitude];
  }
  return (
    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: "column", boxSizing: "border-box"}}>
        <div className='shadowDown' style={{fontSize: "1.7em", paddingLeft: ".5em", paddingTop: ".2em", flexDirection: "row", display: "flex", justifyContent: "space-between"}}>
          <Link to='/map'>
            <IoArrowBackSharp className='hover' style={{cursor: "pointer", borderRadius: "20em", textDecoration: "none", color: "black"}}/>
          </Link>
          <div style={{marginRight: "1em", marginTop: ".1em"}}>
            <span style={{padding:".6em 1em .6em 1em", fontSize: ".6em", borderRadius: "10px"}} className='button' onClick={() => setInfo(["Are you sure?","All data about this parking will be erased!"])}>Delete</span>
          </div>
        </div>
        <div style={{flexGrow: 1, display: 'flex', flexDirection: "row", padding: "2em", boxSizing: "border-box", flexWrap: "wrap", gap:"2em"}}>
            <div style={{display: 'flex', flexDirection: "column"}}>
                <span style={{fontWeight: 700, fontSize: "1.5em", marginBottom: "1em", color: "var(--darkerpurple)"}}>Modify The Parking Space {parking.name.substring(parking.name.lastIndexOf(' '))}</span>
                <div>
                    <input ref={nameRef} className="inputParking" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)}/>
                    <input ref={addressRef} className="inputParking" placeholder='Address' value={address} onChange={(e) => setAddress(e.target.value)} onBlur={() => {provider.search({ query: address }).then(result => {map.setView([result[0].y, result[0].x],16);});}}/>
                </div>
                <div style={{display: "flex", gap:".8em", flexDirection: "column"}}>
                    <span style={{fontWeight: 700, fontSize: "1.2em", color: "var(--darkerpurple)"}}>Open Time</span>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <div>
                            <span style={{fontWeight: 700, fontSize: "1.1em", marginRight: "1em"}}>From:</span>
                            <input ref={fromRef} className="inputParking" type='time' value={from} onChange={(e) => setFrom(e.target.value)}/>
                        </div>
                        <div>
                            <span style={{fontWeight: 700, fontSize: "1.1em", marginRight: "1em"}}>To:</span>
                            <input ref={toRef} className="inputParking" type='time' value={to} onChange={(e) => setTo(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div>
                    {locker === 0 && <input ref={spotsRef} type='number' className="inputParking" placeholder='Number of Spots' value={spots} onChange={(e) => setSpots(e.target.value)}/>}
                    <input ref={priceRef} type='number' className="inputParking" placeholder='Price' value={price} onChange={(e) => setPrice(e.target.value)}/>
                </div>
                <div>
                    <span style={{fontWeight: 700, fontSize: "1.2em", marginRight: "1em", color: "var(--darkerpurple)"}}>Locker:</span>
                    <select name="Locker" style={{fontWeight: 500, fontSize: "1.2em"}} value={locker} onChange={(e) => setLocker(parseInt(e.target.value))}>
                        <option value="0">Normal</option>
                        <option value="1">With Locker</option>
                    </select>
                </div>
                <div style={{display: "flex", flexDirection: "column", marginTop: "2em", gap:"1.5em"}}>
                    <span style={{fontWeight: 700, fontSize: "1.3em", color: "var(--darkerpurple)"}}>Facilities</span>
                    {facilities.map((e,i) => 
                        <div style={{display: "flex"}} key={i}>
                            <input style={{marginBottom: "0px"}} className="inputParking" placeholder='Facility' value={e} onInput={(obj) => setFacilities(facilities.map((element,index) => i === index ? obj.target.value : element))}/>
                            <span style={{padding:".4em .6em .3em .6em", borderRadius: "8px", fontSize: "1.3em", top:"50%"}} className='button' onClick={() => {if(facilities.length === 1)return; setFacilities(facilities.filter((element,index) => i !== index))}}>X</span>
                        </div>)}
                    <div>
                        <span style={{padding:".3em .6em .3em .6em", borderRadius: "8px", fontSize: "1.3em"}} className='button' onClick={() => {if(facilities.length === 5)return; setFacilities([...facilities,""]);}}>+</span>
                    </div>
                </div>
                <div style={{display: "flex", marginTop: "2em", gap: "1em"}}>
                    <span className='button' onClick={() => setCoords(coords.slice(0,-1))}>Delete Last Set of Coordinates</span>
                    <span className='button' onClick={handleModify}>Modify Parking Space</span>
                </div>
            </div>
            <div style={{flexGrow: 1, minHeight: "400px", maxHeight: "calc(100vh - 6em)"}}>
                <MapContainer style={{width: '100%' , height: '100%',zIndex:"0"}} center={getCoordsArray()} zoom={16} ref={setMap}>
                    <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <AddMarkers />
                    {coords.length !== 2 ? <Polygon pathOptions={{ color: 'purple' }} positions={coords}/>
                    : <Circle center={coords[0]} pathOptions={{ color: 'purple' }} radius={getDistance({latitude: coords[0][0], longitude: coords[0][1]},{latitude: coords[1][0], longitude: coords[1][1]})} />}
                </MapContainer>
            </div>
        </div>
        {info.length !== 0 && <div style={{position: "absolute", top: "20%", left:"50%", transform:"translateX(-50%)", width: "min(600px,90%)", minHeight: "min(250px,45%)", backgroundColor: "white",zIndex:"1", animation:".7s fadeIn ease", display: "flex", flexDirection:"column", borderRadius: "20px", padding: "2em", boxSizing: "border-box", gap: "1em", justifyContent: "space-between", border: "2px solid var(--purple)"}}>
            <div style={{display : "flex", flexDirection: "column", gap: "1em"}}>
                <div style={{borderBottom: "2px solid var(--purple)"}}>
                    <span style={{fontWeight: 700, fontSize: "1.9em", marginBottom: "1em", color: "var(--darkerpurple)"}}>{info[0]}</span>
                </div>
                <div>
                    <span style={{fontWeight: 500, fontSize: "1.4em"}}>{info[1]}</span>
                </div>
            </div>
            {info[0] !== "Are you sure?" ? <div style={{display: "flex", justifyContent: "flex-end"}}>
                <span className='button' style={{padding: ".7em 2em .7em 2em", borderRadius: "10px"}} onClick={() => setInfo([])}>Okay</span>
            </div> :
            <div style={{display: "flex", justifyContent: "flex-end", gap: "1em"}}>
                <span className='button' style={{padding: ".7em 2em .7em 2em", borderRadius: "10px"}} onClick={() => setInfo([])}>No</span>
                <span className='button' style={{padding: ".7em 2em .7em 2em", borderRadius: "10px"}} onClick={handleDelete}>Yes</span>
            </div> }
        </div>}
    </div>
  )
}

import { useContext, useEffect, useState } from 'react';
import { GoSearch } from 'react-icons/go';
import { userInstance } from '../util/instances';
import Switch from '../util/Switch';
import { UserContext } from '../util/UserContext';

export const Users = () => {  
  const [active, setActive] = useState(0);
  const [register, setRegister] = useState(0);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [ID, setID] = useState(null);
  const context = useContext(UserContext);

  const activateUSER = (id,status) => {
    userInstance(context).put("/active",{ID:id, active: status ? 1 : 0})
    .then(req => {
      userInstance(context).get(page + "/" + limit, {params: {search: search}})
      .then(req => req.data)
      .then(data => {
        setUsers(data.users);
        setTotal(data.total);
        setActive(data.active);
        setRegister(data.register);
      })
    })
  }

  const handleCurrency = (id,currency) => {
    userInstance(context).put("/currency",{ID:id, currency: currency})
    .then(req => {
      userInstance(context).get(page + "/" + limit, {params: {search: search}})
      .then(req => req.data)
      .then(data => {
        setUsers(data.users);
        setTotal(data.total);
        setActive(data.active);
        setRegister(data.register);
      })
    })
  }

  useEffect(() => {
    setPage(0);
  }, [limit])

  useEffect(() => {
    setPage(0);
  }, [search])
  

  useEffect(() => {
    if(page === 0){
      setPage(1);
      return;
    }
    const update = () => {
      userInstance(context).get(page + "/" + limit, {params: {search: search}})
      .then(req => req.data)
      .then(data => {
        setUsers(data.users);
        setTotal(data.total);
        setActive(data.active);
        setRegister(data.register);
      })
    }

    update();
  }, [page]) 
  
  return (
    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: "column", padding: "2em", boxSizing: "border-box", backgroundColor: ID === null ? "white": "rgba(215,215,215,0.54)", transition: ".6s ease"}}>
      <div className='shadow' style={{position: "absolute", backgroundColor: "white", transition: ".5s ease", width: ID !== null ? "min(400px, 80%)" : 0, display: "flex", left: "50%", top: "35%", transform: "translate(-50%,-50%)", overflow: "hidden", opacity: ID !== null ? 1 : 0, flexDirection: "column", borderRadius: "10px", padding: "2em", fontSize: "1.2em", gap:".2em", boxSizing: "border-box"}}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1em"}}>
            <span style={{fontSize: "1.2em", fontWeight: "900"}}>Edit User</span>
            <span className='hover' style={{cursor: "pointer", borderRadius: "12px", fontWeight: "600"}} onClick={() => setID(null)}>X</span>
          </div>
          <div style={{display: "flex", flexWrap: "wrap"}}>
            <span>Email:</span>
            <span style={{marginLeft: ".5em"}}>{users[ID]?.email}</span>
          </div>
          <div style={{display: "flex", flexWrap: "wrap"}}>
            <span>Phone:</span>
            <span style={{marginLeft: ".5em"}}>{users[ID]?.phone}</span>
          </div>
          <div style={{display: "flex", flexWrap: "wrap"}}>
            <span>Name:</span>
            <span style={{marginLeft: ".5em"}}>{users[ID]?.fullname}</span>
          </div>
          <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", marginTop: "1em"}}>
            <Switch defaultV={users[ID]?.active === 1 ? true : false} onChange={(e) => {activateUSER(users[ID].ID,e);}} label={ID + 'isactive'} key={ID}/>
            <span style={{marginLeft: ".5em"}}>isActive</span>
          </div>
          <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", gap:"1em", fontSize: "1.1em", marginTop: "1em"}}>
            <span className='button' style={{padding: ".4em .5em .4em .5em", borderRadius: "10px"}} onClick={() => handleCurrency(users[ID].ID,-10)}>-10</span>
            <span>{users[ID]?.currency}</span>
            <span className='button' style={{padding: ".4em .5em .4em .5em", borderRadius: "10px"}} onClick={() => handleCurrency(users[ID].ID,10)}>+10</span>
          </div>
        </div>
        <div style={{display: "flex", flexWrap: 'wrap', gap: "1em", margin: "0 0 2em 0", transition: "1s ease"}}>
            <div className='shadowLight' style={{padding: "1em", border: "2px solid transparent", borderRadius: "10px", marginRight: "2em", transition: "1s ease"}}>
                <span>{register} registered users</span>
            </div>
            <div className='shadowLight' style={{padding: "1em", border: "2px solid transparent", borderRadius: "10px", marginRight: "2em"}} >
                <span>{active} active users</span>
            </div>
        </div>
        <div className='shadow' style={{display: "flex", flexDirection: "column", borderRadius: "12px"}}>
          <div style={{padding: "1.5em 1.5em .2em 1.5em", borderBottom:"1px solid gray"}}>
            <span style={{fontSize: "1.8em", fontWeight: "600"}}>Parko Users</span>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", padding: "1em 2em 1em 1em"}}>
            <div className='search'>
              <div style={{display: "flex"}}>
                <input onBlur={e => setSearch(e.target.value)} placeholder='Search' style={{fontSize: "1.2em", border: "none", padding: ".2em .5em .2em .3em", fontWeight: "500", backgroundColor: "transparent"}}/>
                <GoSearch className='searchInside' />
              </div>
            </div>
          </div>
          <div style={{overflowX: "auto", maxWidth: "calc(100vw - 4em)"}}>
          <table style={{paddingLeft: "1em", paddingRight: "1em", minWidth: "100%"}}>
            <tr style={{fontSize: "1.2em", fontWeight: "600", paddingBottom: ".2em"}}>
              <th style={{textAlign: "center", borderRight: "1px solid gray", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>Email</th>
              <th style={{textAlign: "center", borderRight: "1px solid gray", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>Phone</th>
              <th style={{textAlign: "center", borderRight: "1px solid gray", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>Name</th>
              <th style={{textAlign: "center", borderRight: "1px solid gray", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>Currency</th>
              <th style={{textAlign: "center", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>Active</th>
            </tr>
            
              {users.map((e,i) => 
              <tr className='hover' style={{ marginTop: ".7em", fontSize: "1.2em", cursor: "pointer", whiteSpace: "nowrap"}} key={i} onClick={() => setID(i)}>
                <td style={{textAlign: "center", borderRight: "1px solid gray", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>{e.email}</td>
                <td style={{textAlign: "center", borderRight: "1px solid gray", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>{e.phone}</td>
                <td style={{textAlign: "center", borderRight: "1px solid gray", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>{e.fullname}</td>
                <td style={{textAlign: "center", borderRight: "1px solid gray", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>{e.currency}</td>
                <td style={{textAlign: "center", padding: "0 .5em 0 .5em", boxSizing: "border-box"}}>{e.active === 1 ? 'yes' : 'no'}</td>
              </tr> )}
          </table>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", gap: "1em", fontSize:"1.2em", padding: "1em", alignItems: "center"}}>
            <span style={{marginRight: "-.6em", fontWeight: "400"}}>Rows per page:</span>
            <select style={{fontSize: ".9em", backgroundColor: "transparent"}} value={limit} onChange={e => setLimit(e.target.value)}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </select>
            <span>{page} of {Math.round(total / limit) === 0 ? 1 : Math.ceil(total / limit)}</span>
            <span className='hover' style={{padding: ".5em", cursor: "pointer", borderRadius: "10px", marginRight: "-.8em"}} onClick={() => {if(page <= 1)return; setPage(page-1);}}>{'<'}</span>
            <span className='hover' style={{padding: ".5em", cursor: "pointer", borderRadius: "10px"}} onClick={() => {if(page >= Math.ceil(total / limit))return; setPage(page+1);}}>{'>'}</span>
          </div>
        </div>
    </div>
  )
}

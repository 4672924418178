import { useContext, useState } from 'react'
import '../index.css';
import { authInstance } from '../util/instances';
import Logo from '../util/Logo';
import { UserContext } from '../util/UserContext';

export const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const context = useContext(UserContext);

    const handleLogin = () => {
        if(username === "")
            return;
        if(password === "")
            return;
        authInstance(context).post("/login", {username: username, password: password})
        .then(req => {
            context.setLoggedIn(true);
            context.setUsername(username);
        }).catch(error => {
            alert("There was a problem while logging!");
        })
    }
  return (
    <div>
        <div style={{top: "40%", left: "50%", transform: "translate(-50%,-50%)",display: "flex", flexDirection: "column", position: "absolute", alignItems: "center", gap: "1em"}}>
            <Logo style={{width: "min(30em, 90vw)"}}/>
            <input value={username} onChange={(e) => setUsername(e.target.value)} style={{margin: "0px"}} placeholder='Username' className='inputParking'/>
            <input value={password} onChange={(e) => setPassword(e.target.value)} type='password' style={{margin: "0px"}} placeholder='Password' className='inputParking'/>
            <span style={{alignSelf: "flex-end", padding: ".7em .9em .7em .9em", borderRadius: "10px", marginRight: "2em"}} className='button' onClick={handleLogin}>Login</span>
        </div>
    </div>
  )
}

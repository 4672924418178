import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
  } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

const options = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Parking Use',
      },
      tooltip: {
        intersect: false
      }
    },
  };


export const BarChart = (props) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    const labels = props.data.map(e => e.label.split(" ").pop());
    const values = props.data.map(e => e.value);

    const data = {
    labels,
    datasets: [
        {
        label: 'Percent (%)',
        data: values,
        borderColor: '#890350',
        backgroundColor: '#890350',
        },
    ],
    };
  return (
    <Bar options={options} data={data} style={{...props.style}} />
  )
}


export const LineChart = (props) => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend );
    const labels = props.data.map(e => e.label);
    const values = props.data.map(e => e.value);
    
    const data = {
    labels,
    datasets: [
        {
        label: 'Parkings',
        data: values,
        borderColor: '#890350',
        backgroundColor: '#890350',
        },
    ],
    };
  return (
    <Line options={options} data={data} style={{...props.style}} />
  )
}

import axios from "axios";

const baseURL = "https://mobile.parko.ai/dashboard/"
export const baseURLBookingSystem = "https://mobile.parko.ai/booking/"
// export const baseURLBookingSystem = "http://localhost:5000/"
// const baseURL = "https://admin.parko.bordeac.ro/backend/"
// export const baseURLBookingSystem = "https://parko.bordeac.ro/"

export const authInstance = () => {
    const customInstance = axios.create({
        baseURL: baseURL + "auth/",
        withCredentials: true,
    })
    return customInstance;
};

export const reportInstance = (context) => {
    const customInstance = axios.create({
        baseURL: baseURL + "reports/",
        withCredentials: true,
    });
    customInstance.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        async (error) => {
          const originalConfig = error.config;
          if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
              originalConfig._retry = true;
              return authInstance().get('/refresh').then(response => {
                  if(response.status === 200) {
                      return customInstance(originalConfig);
                  }
              })
              .catch(error => context.setLoggedIn(false));
            }
          }
      
          return Promise.reject(error);
        }
    );
    return customInstance;
};

export const userInstance = (context) => {
    const customInstance = axios.create({
        baseURL: baseURL + "users/",
        withCredentials: true,
    });
    customInstance.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        async (error) => {
          const originalConfig = error.config;
          if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
              originalConfig._retry = true;
              return authInstance().get('/refresh').then(response => {
                  if(response.status === 200) {
                      return customInstance(originalConfig);
                  }
              })
              .catch(error => context.setLoggedIn(false));
            }
          }
      
          return Promise.reject(error);
        }
    );
    return customInstance;
};

export const parkingInstance = (context) => {
    const customInstance = axios.create({
        baseURL: baseURL + "parking/",
        withCredentials: true,
    });
    customInstance.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        async (error) => {
          const originalConfig = error.config;
          if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
              originalConfig._retry = true;
              return authInstance().get('/refresh').then(response => {
                  if(response.status === 200) {
                      return customInstance(originalConfig);
                  }
              })
              .catch(error => context.setLoggedIn(false));
            }
          }
      
          return Promise.reject(error);
        }
    );
    return customInstance;
};
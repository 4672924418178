
const Logo = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 1442 386"
    style={{
      ...props.style,
      enableBackground: "new 0 0 1442 386",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#8c034e}.st1{fill:#f28705}"}</style>
    <path
      className="st0"
      d="M246.7 70.4c13.2 6 23.3 14.4 30.4 25.4 7.1 11 10.7 23.9 10.7 38.9 0 14.8-3.6 27.7-10.7 38.8-7.1 11.1-17.3 19.5-30.4 25.4-13.2 5.9-28.7 8.8-46.6 8.8h-40.7v55.7h-46.7v-202h87.4c17.9 0 33.4 3 46.6 9zm-17.1 90.1c7.3-6.1 11-14.7 11-25.8 0-11.3-3.7-20-11-26.1-7.3-6.1-18-9.1-32-9.1h-38.1v70.1h38.1c14 0 24.7-3.1 32-9.1zM457.7 108.1v155.2h-43v-17.9c-11.2 13.5-27.3 20.2-48.5 20.2-14.6 0-27.8-3.3-39.7-9.8-11.8-6.5-21.1-15.9-27.8-28-6.7-12.1-10.1-26.1-10.1-42.1s3.4-30 10.1-42.1c6.7-12.1 16-21.4 27.8-28 11.8-6.5 25-9.8 39.7-9.8 19.8 0 35.3 6.3 46.4 18.7v-16.4h45.1zM402.3 217c7.5-7.8 11.2-18.2 11.2-31.3 0-13.1-3.7-23.5-11.2-31.3s-16.9-11.7-28.3-11.7c-11.5 0-21.1 3.9-28.6 11.7s-11.2 18.2-11.2 31.3c0 13.1 3.7 23.5 11.2 31.3 7.5 7.8 17 11.7 28.6 11.7 11.4 0 20.8-3.9 28.3-11.7zM546.7 111.6c9.1-3.8 19.7-5.8 31.6-5.8v41.5c-5-.4-8.4-.6-10.1-.6-12.9 0-23 3.6-30.3 10.8-7.3 7.2-11 18-11 32.4v73.3h-45V108.1h43v20.5c5.4-7.5 12.6-13.2 21.8-17zM654.4 202.4l-21.6 21.3v39.5h-45v-214h45v121.1l65.8-62.3h53.6l-64.6 65.8 70.4 89.4h-54.5l-49.1-60.8z"
    />
    <path
      className="st1"
      d="M1003.3 256.6c-5.4-5.4-8.1-12.1-8.1-20.2 0-8.3 2.7-14.9 8.1-20 5.4-5.1 12-7.6 19.9-7.6 7.9 0 14.5 2.6 19.9 7.6 5.4 5.1 8.1 11.8 8.1 20 0 8.1-2.7 14.8-8.1 20.2-5.4 5.4-12 8.1-19.9 8.1-7.8-.1-14.5-2.7-19.9-8.1zM1237.2 107.2v155.2h-43v-17.9c-11.2 13.5-27.3 20.2-48.5 20.2-14.6 0-27.8-3.3-39.7-9.8-11.8-6.5-21.1-15.9-27.8-28-6.7-12.1-10.1-26.1-10.1-42.1s3.4-30 10.1-42.1c6.7-12.1 16-21.4 27.8-28 11.8-6.5 25-9.8 39.7-9.8 19.8 0 35.3 6.3 46.4 18.7v-16.4h45.1zM1181.9 216c7.5-7.8 11.2-18.2 11.2-31.3 0-13.1-3.7-23.5-11.2-31.3-7.5-7.8-16.9-11.7-28.3-11.7-11.5 0-21.1 3.9-28.6 11.7-7.5 7.8-11.2 18.2-11.2 31.3 0 13.1 3.7 23.5 11.2 31.3 7.5 7.8 17 11.7 28.6 11.7 11.4 0 20.8-3.9 28.3-11.7zM1281.1 78.3c-5.2-4.8-7.8-10.8-7.8-17.9 0-7.1 2.6-13.1 7.8-17.9 5.2-4.8 11.9-7.2 20.2-7.2 8.3 0 15 2.3 20.2 6.9 5.2 4.6 7.8 10.4 7.8 17.3 0 7.5-2.6 13.7-7.8 18.6-5.2 4.9-11.9 7.4-20.2 7.4-8.3 0-15-2.4-20.2-7.2zm-2.3 28.9h45v155.2h-45V107.2z"
    />
    <path
      d="m901.4 319.5 50.3-50.3c45-45 45-118 0-162.9s-118-45-162.9 0c-45 45-45 118 0 162.9l66.1 66.1 15.4 15.4 31.1-31.2zm19.2-81.5-35.3 35.3-15 15-50.4-50.3c-27.8-27.8-27.8-72.9 0-100.7 27.8-27.8 72.9-27.8 100.7 0 27.8 27.9 27.8 72.9 0 100.7z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#8c034e",
      }}
    />
    <path
      d="M901.4 156.6c17.2 17.2 17.2 45.1 0 62.2l-31.1 31.1-31.1-31.1c-17.2-17.2-17.2-45.1 0-62.2s45-17.2 62.2 0z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        fill: "#f28705",
      }}
    />
  </svg>
)

export default Logo

import { useContext, useEffect, useRef, useState } from 'react'
import { BarChart, LineChart } from '../util/Charts'
import { reportInstance } from '../util/instances';
import { UserContext } from '../util/UserContext';

export const Reports = () => {
  const [end, setEnd] = useState(new Date().getTime());
  const [start, setStart] = useState(new Date().getTime() - 24 * 60 * 60 * 1000);
  const [lineData, setLineData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [total, setTotal] = useState(0);
  const startRef = useRef(null);
  const endRef = useRef(null);
  const context = useContext(UserContext);
  useEffect(() => {
    if(start > end) {
      setEnd(start);
      return;
    }
    startRef.current.value = getLocaleISOString(new Date(start));
    endRef.current.value = getLocaleISOString(new Date(end));

    reportInstance(context).get("/" + start + "/" + end)
    .then(request => request.data)
    .then(data => {
      setLineData(data.usings);
      setBarData(data.favs);
      setTotal(data.total);
    })
  }, [start,end])
  
  const getLocaleISOString = (x) => {
    if(! (x instanceof Date))
      return "";
    return x.getFullYear() + "-" + ("0" + (x.getMonth() + 1)).slice(-2) + "-" + ("0" + x.getDate()).slice(-2) + "T" + ("0" + x.getHours()).slice(-2) + ":" + ("0" + x.getMinutes()).slice(-2)
  };

  return (
    <div style={{display: "flex", flexDirection: "column", margin: "2em 1em 1em 1em", boxSizing: "border-box", gap: "3em", alignItems: "center", transition: "1s ease"}}>
      <div style={{display: "flex", flexWrap: "wrap", fontSize: "1.2em", fontWeight: "600", gap: "2em", transition: "1s ease"}}>
        <div style={{display: "flex", alignItems: "center", gap: "1em", flexWrap: "wrap"}}>
          <span>From: </span>
          <input style={{fontSize: "1.2em", borderTop: ".2em solid black",borderLeft: ".2em solid black", borderBottom: ".2em solid var(--purple)", borderRight: ".2em solid var(--purple)", borderRadius: "8px", padding: ".2em .3em .2em .3em"}} type="datetime-local" defaultValue={getLocaleISOString(new Date(start))} onBlur={(e) => setStart(new Date(e.target.value).getTime())} ref={startRef}/>
        </div>
        <div style={{display: "flex", alignItems: "center", gap: "1em", flexWrap: "wrap"}}>
          <span>To: </span>
          <input style={{fontSize: "1.2em", borderTop: ".2em solid black",borderLeft: ".2em solid black", borderBottom: ".2em solid var(--purple)", borderRight: ".2em solid var(--purple)", borderRadius: "8px", padding: ".2em .3em .2em .3em"}} type="datetime-local" defaultValue={getLocaleISOString(new Date(end))} onBlur={(e) => setEnd(new Date(e.target.value).getTime())} ref={endRef}/>
        </div>
      </div>
      <div style={{display: "flex", flexWrap: 'wrap', gap: "2em", transition: "1s ease", boxSizing: "border-box"}}>
          <div className='shadowLight' style={{padding: "1em", border: "2px solid transparent", borderRadius: "10px", transition: "1s ease", flexWrap: "wrap"}}>
              <span style={{fontWeight: "600"}}>Total coins: </span>
              <span>{total}</span>
          </div>
          <div className='shadowLight' style={{padding: "1em", border: "2px solid transparent", borderRadius: "10px", transition: "1s ease", flexWrap: "wrap"}} >
              <span style={{fontWeight: "600"}}>Most used parking: </span>
              <span>{barData[0]?.label}</span>
          </div>
      </div>
      <div style={{width: "90%", display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
        <div style={{flex: 1}}>
          <LineChart style={{maxHeight: "30em"}} data={lineData}/>
        </div>
        <div style={{flex: 1}}>
          <BarChart style={{maxHeight: "30em"}} data={barData}/>
        </div>
      </div>
    </div>
  )
}

import { useState } from 'react';
import sw from './css/Switch.module.css';

const Switch = ({defaultV ,onChange ,label}) => {
  const [check, setCheck] = useState(defaultV);
  return (
    <>
      <input
        className={sw.checkbox}
        id={label}
        type="checkbox"
        onChange={(e) => {setCheck(!check);onChange(!check)}}
        checked={check}
      />
      <label
        className={sw.label}
        htmlFor={label}
      >
        <span className={sw.button} />
      </label>
    </>
  );
};

export default Switch;
import { Circle, MapContainer, Polygon, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { Link, useOutletContext } from 'react-router-dom';
import { getDistance } from 'geolib';

export const Map = () => {
    const [showMenu, parking] = useOutletContext();
    
  return (
    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: "column"}}>
        <div style={{display: "flex", padding: "1em 1em 0 1em"}}>
            <Link to={'add'} className='button'>
                <span>Add Parking Area</span>
            </Link>
        </div>      
        <div style={{flexGrow: 1, margin: "2em", overflow: "hidden"}}>
        <MapContainer style={{width: '100%' , height: '100%'}} center={[44.4234633061, 26.1013029281]} zoom={13}>
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {parking.map((e,i) => <>
                {e.coordinates.length !== 2 ? <Polygon pathOptions={{ color: e.status === 0 ? 'purple' :(e.free === 1 ? 'green' : 'red') }} positions={e.coordinates.map(e => [e.latitude, e.longitude])} key={i}>
                    <Popup>
                        <div style={{display: "flex", flexDirection: "column", gap: ".2em"}}>
                            <span>Name: {e.name}</span>
                            <span>Address: {e.address} </span> 
                            <span>Price: {e.price} coins </span>
                            <span>Locker: {e.status === 0 ? 'false' : 'true'} </span>
                            <Link to={'modify'} state={{parking: e}} style={{padding:".3em .4em .3em .4em", textAlign: "center", textDecoration: "none", color: "white"}} className='button'>Modify</Link>
                        </div>
                    </Popup>
                </Polygon> : 
                <Circle center={[e.coordinates[0].latitude, e.coordinates[0].longitude]} pathOptions={{ color: e.status === 0 ? 'purple' :(e.free === 1 ? 'green' : 'red') }} radius={getDistance(e.coordinates[0],e.coordinates[1])} key={i}>
                    <Popup>
                        <div style={{display: "flex", flexDirection: "column", gap: ".2em"}}>
                            <span>Name: {e.name}</span>
                            <span>Address: {e.address} </span> 
                            <span>Price: {e.price} coins </span>
                            <span>Locker: {e.status === 0 ? 'false' : 'true'} </span>
                            <Link to={'modify'} state={{parking: e}} style={{padding:".3em .4em .3em .4em", textAlign: "center", textDecoration: "none", color: "white"}} className='button'>Modify</Link>
                        </div>
                    </Popup>
            </Circle>
            }</>)}
        </MapContainer>
        </div>
    </div>
  )
}
